<template>
  <div class="dialog-container">
    <el-dialog
        title="图片打码：可在相应位置滑动对隐私信息打码"
        :visible.sync="visible"
        :modal-append-to-body="true"
        :append-to-body="true"
        :fullscreen="true"
        width="100%">
<!--      <div class="dialog-title"></div>-->
      <el-image
          v-show="false"
          style="width: 100%; height: auto"
          :src="imgUrl" @load="load"></el-image>
      <div class="work-wrap">
        <canvas id="canvas" class=""/>
      </div>
      <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="saveHandle">确 定</el-button>
    </span>
    </el-dialog>

    <div id="imgForm">

    </div>
  </div>
</template>

<script>
import Mosaic from "image-mosaic";
import {getQueryVariable} from "@/libs/util";

export default {
  name: "icu-dialog",
  props: {
    visible: {
      type: Boolean
    },
    imgUrl: {
      type: String,
    },
    imgType: {
      type: String,
    }
  },
  data() {
    return {
      dialogVisible: true,
      fit: []

    }
  },
  watch: {},
  created() {
  },
  methods: {
    load() {
      // console.log('111111')
      this.initMosaic();
    },
    handleClose() {
      this.$emit('handleClose')
    },
    drawImageToCanvas(imageUrl) {
      const canvas = document.querySelector('#canvas')
      const ctx = canvas.getContext('2d')

      return new Promise((resolve, reject) => {
        const image = new Image()
        image.crossOrigin = 'Annoymous'
        image.onload = function () {
          console.log(image.width)
          // canvas.width = document.body.clientWidth - 40
          canvas.width =image.width
          // canvas.height = document.body.clientWidth + 200
          canvas.height = image.height
          ctx.drawImage(this, 0, 0, canvas.width, canvas.height)
          resolve(ctx)
        }
        image.src = imageUrl
      })
    },
    initMosaic() {
      this.drawImageToCanvas(this.imgUrl).then((ctx) => {
        const mosaic = new Mosaic(ctx)
        const MouseEvents = {
          init() {
            mosaic.context.canvas.addEventListener(
                'mousedown',
                MouseEvents.mousedown
            )
            mosaic.context.canvas.addEventListener(
                'touchstart',
                MouseEvents.touchstart
            )
          },
          mousedown() {
            mosaic.context.canvas.addEventListener(
                'mousemove',
                MouseEvents.mousemove
            )
            document.addEventListener('mouseup', MouseEvents.mouseup)
          },
          touchstart() {
            mosaic.context.canvas.addEventListener(
                'touchmove',
                MouseEvents.touchmove
            )
            document.addEventListener('touchend', MouseEvents.touchend)
          },
          mouseup() {
            mosaic.context.canvas.removeEventListener(
                'mousemove',
                MouseEvents.mousemove
            )
            document.removeEventListener('mouseup', MouseEvents.mouseup)
          },
          touchend() {
            mosaic.context.canvas.removeEventListener(
                'touchmove',
                MouseEvents.touchmove
            )
            document.removeEventListener('touchend', MouseEvents.touchend)
          },
          mousemove(e) {
            if (e.shiftKey) {
              mosaic.eraseTileByPoint(e.layerX, e.layerY)
              return
            }
            mosaic.drawTileByPoint(e.layerX, e.layerY)
          },
          touchmove(e) {
            let y = e.touches[0].clientY;
            if(y > 84){
              y = y - 84
            }
            if (e.shiftKey) {
              mosaic.eraseTileByPoint(e.touches[0].clientX, y)
              return
            }

            mosaic.drawTileByPoint(e.touches[0].clientX, y)
          },

        }
        MouseEvents.init()
      })
    },
    saveHandle() {
      let localData = document.querySelector('#canvas').toDataURL('image/png');
      // if (localData.indexOf('data:image') !== 0) {
      //   //判断是否有这样的头部
      //   localData = 'data:image/jpeg;base64,' + localData;
      // }
      // localData = localData.replace(/\r|\n/g, '').replace('data:image/jgp', 'data:image/jpeg'); // 此处的localData 就是你所需要的base64位

      // let form = document.imgForm;
      //
      let formData = new FormData();
      let fileName = new Date().getTime() + ".jpeg";
      formData.append("file", this.dataURLtoFile(localData, fileName))
      // console.log(formData)
      this.uploadFile(formData)
      // this.$emit('saveHandle',localData)
    },


    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },
    uploadFile(formData) {
      this.$loading()
      let created_by = getQueryVariable('created_by');
      let template_id = parseInt(getQueryVariable('template_id'));
      let patient_id = parseInt(getQueryVariable('patient_id'));
      let query = {
        created_by: created_by,
        template_id: template_id,
        patient_id: patient_id,
      }
      this.$ajaxApi.dataApi.uploadFile(query, formData)
          .then(res => {
            this.$loading().close();
            if (res.data.status_code === 200) {
              // let obj = {
              //   // name: file.name,
              //   url: res.data.data.url,
              // }
              // this.fileList.push(obj);
              let returnData = {}
              returnData[this.imgType] = res.data.data.url;
              this.$emit('returnImages', returnData);
              this.handleClose();

            } else {
              this.$message(res.data.message);
            }
          })
          .catch(error => {
            this.$loading().close();
            console.log(error)
          })
    },

  }
}
</script>

<style scoped>
/deep/ .el-dialog__title{
  font-size: 16px;
}

.work-wrap {
  width: 100%;
  text-align: center;
  /*height: auto;*/
}
.dialog-title{
  padding: 30px 0;
  font-size: 18px;
}
</style>
