<style scoped>
.upload-operation {
  display: flex;
  justify-content: space-around;
}

.upload-btn {
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #44A4E6;
  padding: 9px 28px;
  color: #44A4E6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-scan-btn {
  margin-left: 25px;
  background-color: #44A4E6;
  color: #FFFFFF;
}

.btn-icon {
  width: 18px;
  height: 18px;
  padding-right: 9px;
}

.upload-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 11px;
}

.upload-img {
  width: 46px;
  height: 32px;
  margin: 4px 4px;
}
</style>
<template>
  <div class="upload-wrap" v-if="isSelect == 'false'">
    <div id="imgForm">

    </div>
    <div class="upload-operation">

<!--      <button class="upload-btn " type="button" v-if="equipment" @click="chooseImage"><img src="../assets/photo.png"-->
<!--                                                                                           alt="" class="btn-icon">点击拍照-->
<!--      </button>-->
      <el-upload
          class="upload-demo test"
          :action="this.$ajaxApi.dataApi.uploadFileUrl"
          :headers="headers"
          :data="fileData"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-progress="handleProgress"
          :before-upload="beforeUpload"
          :before-remove="beforeRemove"
          :on-success="handleSuccess"
          :show-file-list="false"
          :file-list="fileList"
          :accept="'image/*'"
          :multiple="false"
      >
        <button class="upload-btn " type="button"><img src="../assets/photo.png" alt="" class="btn-icon">点击拍照</button>
      </el-upload>
      <button class="upload-btn upload-scan-btn" type="button" @click="textRecognition"><img src="../assets/scan.png"
                                                                                             alt="" class="btn-icon">开始识别
      </button>
    </div>
    <div>

    </div>
    <div class="upload-list">
<!--      <img v-for="(item,index) in imageList" :src="item" alt="" :key="index" v-if="imageList.length > 0 && index <= 11" class="upload-img"/>-->

            <div v-for="(item,index) in imageList" :key="index" >
              <el-image
                  v-if="imageList.length > 0 && index <= 11"
                  class="upload-img"
                  :src="item"
                  :preview-src-list="imageList">
              </el-image>
            </div>
<!--      <img v-for="(item,index) in fileList" :src="item.url" alt=""  :key="index" v-if="" class="upload-img"/>-->
    </div>
  </div>
</template>

<script>
import {getQueryVariable} from "@/libs/util";

export default {
  name: "icu-upload",
  props: {
    type: {
      type: String
    },
    baseUrl: {
      type: String
    },
    equipment: {
      type: Boolean
    },
    imageList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      fileList: [],
      isSelect: '',
      headers: {},
      fileData: {},
    }
  },
  watch:{
    baseUrl(value){
      // console.log(value)
    }
  },
  created() {
    this.isSelect = getQueryVariable('isSelect');

    let token = getQueryVariable('token');
    this.headers = {
      'x-client-tag': 'MP',
      'x-token': token,
    }
    let created_by = getQueryVariable('created_by');
    let template_id = parseInt(getQueryVariable('template_id'));
    let patient_id = parseInt(getQueryVariable('patient_id'));
    this.fileData = {
      created_by: created_by,
      template_id: template_id,
      patient_id: patient_id,
    }
  },
  methods: {
    handleRemove(file, fileList) {
      // console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleProgress(file) {
      console.log(file);

      this.$loading()
    },
    // eslint-disable-next-line no-unused-vars
    handleSuccess(response, file, fileList) {
      console.log(file)
      this.$loading().close();
      if (response.status_code === 200) {
        let obj = {
          // name: file.name,
          url: response.data.url,
        }
        this.fileList.push(obj);
        console.log(this.type, '11111')
        let returnData = {}
        returnData[this.type] = response.data.url;
        this.$emit('returnImages', returnData);
      } else {
        this.$message(response.message);
      }
    },
    beforeRemove(file) {
      // return this.$confirm(`确定移除 ${ file.name }？`);
    },

    //开始识别
    textRecognition() {

      if (this.imageList.length <= 0) {
        this.$message("请先上传照片");
        return;
      }
      let created_by = getQueryVariable('created_by');
      let template_id = parseInt(getQueryVariable('template_id'));
      this.$loading({
        text: '识别中,请耐心等待'
      })
      let image_list = [];
      this.imageList.forEach(item => {
        image_list.push(item)
      })
      let data = {
        created_by: created_by,
        template_id: template_id,
        image_list: image_list,
        type: this.type
      }
      this.$ajaxApi.dataApi.textRecognition(data)
          .then(res => {
            this.$loading().close();
            if (res.data.status_code === 200) {
              this.$message(res.data.message);
              this.$emit('returnRes', res.data.data)
            } else {
              this.$message(res.data.message);
            }
          })
          .catch(error => {
            this.$loading().close();
            console.log(error)
          })
    },


    chooseImage() {
      console.log("1111")
      this.$wx.ready(() => {
        console.log("2222")
        this.$wx.chooseImage({
          count: 1, // 默认9
          sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
          success: (res) => {
            let localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
            console.log(localIds);
            localIds.forEach(item => {
              this.$wx.getLocalImgData({
                localId: item, // 图片的localID
                success: (res) => {
                  this.$loading()
                  let localData = res.localData; // localData是图片的base64数据，可以用img标签显示
                  if (localData.indexOf('data:image') !== 0) {
                    //判断是否有这样的头部
                    localData = 'data:image/jpeg;base64,' + localData;
                  }
                  localData = localData.replace(/\r|\n/g, '').replace('data:image/jgp', 'data:image/jpeg'); // 此处的localData 就是你所需要的base64位
                  let data = {
                    url:localData,
                    type:this.type,
                  }
                  // this.$emit('showDialog',data)
                  let form = document.imgForm;
                  //
                  let formData = new FormData(form);
                  let fileName = new Date().getTime() + ".jpeg";
                  formData.append("file", this.dataURLtoFile(localData, fileName))
                  this.uploadFile(formData);
                }
              });
            })
          }
        });

      });
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },
    //上传图片
    uploadFile(formData) {
      let created_by = getQueryVariable('created_by');
      let template_id = parseInt(getQueryVariable('template_id'));
      let patient_id = parseInt(getQueryVariable('patient_id'));
      let query = {
        created_by: created_by,
        template_id: template_id,
        patient_id: patient_id,
      }
      this.$ajaxApi.dataApi.uploadFile(query, formData)
          .then(res => {
            this.$loading().close();
            if (res.data.status_code === 200) {
              let obj = {
                // name: file.name,
                url: res.data.data.url,
              }
              this.fileList.push(obj);
              console.log(this.type, '11111')
              let returnData = {}
              returnData[this.type] = res.data.data.url;
              this.$emit('returnImages', returnData);
            } else {
              this.$message(res.data.message);
            }
          })
          .catch(error => {
            this.$loading().close();
            console.log(error)
          })
    },

    //上传之前阻断
    beforeUpload(file) {
      // this.fileToBase(file)
      // return false
      // return this.$confirm(`确定移除 ${ file.name }？`);

    },
    //file转base64
    fileToBase(file){
      let that = this;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        let data = {
          url:reader.result,
          type:that.type,
        }
        console.log(reader.result)
        that.$emit('showDialog',data)
      }
    },

  }
}
</script>

