import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('@/views/case'),
  },
  {
    path: '/pyemia',
    name: 'pyemia',
    component: () => import('@/views/pyemia'),
  },
  {
    path: '/arrhythmia',
    name: 'arrhythmia',
    component: () => import('@/views/arrhythmia'),
  },
  {
    path: '/processing',
    name: 'processing',
    component: () => import('@/views/processing'),
  },



]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
