// eslint-disable-next-line no-unused-vars
const medical = [
  {
    type: 'input',
    name: 'name',
    label: '患者姓名',
    rules: [
      {required: true, message: '请输入患者姓名', trigger: 'blur'},
    ]
  },
  {
    type: 'radio',
    name: 'sex',
    label: '患者性别',
    rules: [
      {required: true, message: '请选择患者性别', trigger: 'change'},
    ],
    options: [{
      label: '男',
      value: 1,
    }, {
      label: '女',
      value: 2
    },]
  },
  {
    type: 'input',
    name: 'age',
    label: '患者年龄',
    rules: [
      {required: true, message: '请输入患者年龄', trigger: 'blur'},
    ],
  },
  {
    type: 'input',
    name: 'height',
    label: '患者体重',
    rules: [
      {required: true, message: '请输入患者体重', trigger: 'blur'},
    ],
  },
  {
    type: 'radio',
    name: 'is_referral',
    label: '是否转诊',
    hasInput:true,
    slotName:'office',
    slotLabel:'科室',
    options: [{
      label: '否',
      value:0,
    }, {
      label: '是',
      value:1,
    },]
  },
  {
    type: 'hidden',
    name: 'office',
  },
  {
    type: 'date',
    name: 'admission_time',
    label: '入院时间',
    rules: [
      {required: true, message: '请选择入院时间', trigger: 'change'}
    ],
  },
  {
    type: 'textarea',
    name: 'previous_hostory',
    label: '既往史',
  },
  {
    type: 'radio',
    name: 'hypertension_hostory',
    label: '是否有高血压病史',
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },]
  },


  {
    type: 'check',
    name: 'diagnosis_admission',
    label: '入院诊断 ',
    rules: [
      { type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
      ],
    options: [
      {
        label: 'DebakeyⅠ型',
      }, {
        label: 'Stanford A型',
      }, {
        label: 'DebakeyⅡ型',
      }, {
        label: 'Stanford B型',
      },{
        label: 'DebakeyⅢ型 ',
      },]
  },

  {
    type: 'input',
    name: 'other_admission',
    label: '其他诊断结果',
  },
  {
    type: 'textarea',
    name: 'sympotms_signs',
    label: '症状体征',
  },
  {
    type: 'radio',
    name: 'is_chest_abdomen_back_pain',
    label: '是否胸腹或背部疼痛',
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },]
  },
  {
    type: 'input',
    name: 'duration_of_pain',
    label: '疼痛持续时间',
    placeholder: '请输入疼痛持续时间',
  },
  {
    type: 'input',
    name: 'hr',
    label: '心率HR(次/分)',
    placeholder: '请输入初始检查结果',
  },
  {
    type: 'input',
    name: 'bp',
    label: '血压BP(mmHg)',
    placeholder: '请输入初始检查结果',
  },
  {
    type: 'input',
    name: 'temperature',
    label: '体温T(℃)',
    placeholder: '请输入初始检查结果',
  },
  {
    type: 'input',
    name: 'pulse',
    label: '脉搏P（次/分）',
    placeholder: '请输入初始检查结果',
  },
  {
    type: 'input',
    name: 'breathe',
    label: '呼吸R（次/分）',
    placeholder: '请输入初始检查结果',
  },
  {
    type: 'input',
    name: 'd_dimer',
    label: 'D-二聚体（ug/L）',
    placeholder: '请输入初始检查结果',
  },
  {
    type: 'radio',
    name: 'is_operation',
    label: '是否手术',
    options: [{
      label: '否',
      value: 0,
    }, {
      label: '是',
      value: 1,
    },]
  },
  {
    type: 'date',
    name: 'operation_time',
    label: '手术时间',
    // rules: [
    //   { required: true, message: '请选择日期', trigger: 'change'}
    // ],
  },
]
const prescribed = [
  {
    type: 'date',
    name: 'start_time',
    label: '使用时间',
    rules: [
      {required: true, message: '请选择使用时间', trigger: 'change'},
    ]
  },
  {
    type: 'date',
    name: 'end_time',
    label: '结束时间',
    rules: [
      {required: true, message: '请选择结束时间', trigger: 'change'},
    ]
  },
  {
    type: 'radio',
    name: 'method',
    label: '使用方法',
    options: [{
      label: '持续不间断使用',
      value: 0,
    }, {
      label: '持续间断使用',
      value: 1,
    },],
    rules: [
      {required: true, message: '请选择使用方法', trigger: 'change'},
    ]
  },

  {
    type: 'check',
    name: 'combination_medication',
    label: '合并用药情况 ',
    rules: [
      { type: 'array', required: true, message: '请选择合并用药情况', trigger: 'change' }
    ],
    options: [
      {
        label: '乌拉地尔',
      }, {
        label: '硝普钠',
      }, {
        label: '地尔硫卓',
      },{
        label: '右美托咪定 ',
      },
      {
        label: '米力农 ',
      },
      {
        label: '左西孟旦 ',
      }
    ]

  },
  {
    type: 'input',
    name: 'dose_range',
    label: '使用剂量范围',
    placeholder:'请输入使用剂量范围（mg/h）',
    rules: [
      {required: true, message: '请输入使用剂量范围（mg/h）', trigger: 'blur'},
    ]
  },
  {
    type: 'input',
    name: 'total',
    label: '使用总量',
    placeholder: '请输入使用总量（mg）',
    rules: [
      {required: true, message: '请输入使用总量（mg）', trigger: 'blur'},
    ]
  },

]
//病程记录
const course = [



  {
    type: 'radio',
    name: 'patient_transition',
    label: '患者转归',
    options: [{
      label: '好转',
      value:0,
    }, {
      label: '未愈',
      value:1,
    }, {
      label: '死亡',
      value:2,

    },]
  },
  {
    type: 'input',
    name: 'hr',
    label: '心率HR(次/分)',
    placeholder: '请输入初始检查结果',
  },
  {
    type: 'input',
    name: 'bp',
    label: '血压BP(mmHg)',
    placeholder: '请输入初始检查结果',
  },
  {
    type: 'input',
    name: 'temperature',
    label: '体温T(℃)',
      placeholder: '请输入初始检查结果',
},
  {
    type: 'input',
    name: 'pulse',
    label: '脉搏P（次/分）',
      placeholder: '请输入初始检查结果',
},
  {
    type: 'input',
    name: 'breathe',
    label: '呼吸R（次/分）',
      placeholder: '请输入初始检查结果',
},
  {
    type: 'input',
    name: 'serum_creatinine',
    label: '血肌酐（umol/L）',
      placeholder: '请输入初始检查结果',
},
  {
    type: 'input',
    name: 'Bnp',
    label: 'BNP/NT-ProBNP',
      placeholder: '请输入初始检查结果',
},
  {
    type: 'input',
    name: 'TnI',
    label: '肌钙蛋白I',
      placeholder: '请输入初始检查结果',
},
  {
    type: 'radio',
    name: 'is_adverse_reaction',
    label: '不良反应',
    options: [{
      label: '无',
      value: 0
    }, {
      label: '有',
      value: 1
    },]
  },
  {
    type: 'input',
    name: 'detail',
    label: '',
    placeholder: '请描述具体情况及措施'
  },
]

export {
  medical,
  prescribed,
  course
}
