import Vue from 'vue'
import {
  Button,
  Input,
  Select,
  Option,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  DatePicker,
  Form,
  FormItem,
  Collapse,
  CollapseItem,
  Upload,
  Dialog,
  Loading,
  Message,
  Image
} from 'element-ui';

import'@/styles/basic.css'
import App from './App.vue'
import ajaxApi from './api/request';
import wx from 'weixin-js-sdk'
import router from './router'
import store from './store'


Vue.config.productionTip = false

Vue.prototype.$ajaxApi = ajaxApi;
Vue.prototype.$wx = wx;

Vue.use(Button);
Vue.use(Select);
Vue.use(Input);
Vue.use(Option);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(DatePicker);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Image);
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;

router.beforeEach((to, from, next) => {
  document.title = '病历填写'
  next();
})

import _ from 'lodash';
Vue.prototype._ = _;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
