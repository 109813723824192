<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

</style>

<script>
export default {
  created() {
    this.wxStartCode();
  },
  methods:{
    wxStartCode() {
      let url = location.href.split("#")[0];
      let data = {
        url: url
      };
      this.$ajaxApi.wechatApi
          .getWechat(data)
          .then(res => {
            if (res.data.status_code === 200) {
              // console.log(res.data.data);
              this.$wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: res.data.data.appid, // 必填，公众号的唯一标识
                timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
                nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
                signature: res.data.data.signature, // 必填，签名，见附录1
                jsApiList: [
                  "chooseImage",
                ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              });
              this.$wx.checkJsApi({
                jsApiList: [
                  "chooseImage",
                ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
                success: function(res) {
                  console.log(res);
                  // 以键值对的形式返回，可用的api值true，不可用为false
                  // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
                }
              });
              this.$wx.error(res => {
                console.log(res)
              });

              // this.$wx.error(function(res) {
              //   // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
              // });
            } else {
              // this.$toast(res.data.message);
            }
          })
          .catch(error => {
            console.log(error.response.data)
          });
    },
  }
}
</script>
