<template>
  <div class="home">
    <div class="content">
      <icuForm v-if="sonRefresh" :formData="formData" :ruleFormData="ruleFormData" type="progress_note" ref="child"
               @returnData="returnData" :isSelect="isSelect" :auditStatus="auditStatus"></icuForm>
    </div>

    <div class="save-wrap">
      <div class="save-tips">注：请将信息填写完整后保存</div>
      <el-button type="" class="save-btn" @click="tsSaveClick" v-if="isSelect == 'false'">暂存</el-button>
      <el-button type="" class="save-btn ts-btn" @click="saveClick" v-if="isSelect == 'false'">保存</el-button>
      <el-button type="" class="save-btn" @click="selectClick" v-if="isSelect == 'true'">选择模板</el-button>
    </div>

  </div>
</template>


<script>
  import wx from 'weixin-js-sdk'
  import {medical, course,prescribed} from '@/database/medicalTemplates'

  import icuForm from '@/components/icu-form'
  import {getQueryVariable} from "@/libs/util";

  export default {
    name: 'Home',
    components: {
      icuForm,
    },
    data() {
      return {
        formData: {
          resident_admit_note: {title: '入院记录', list: medical},
          prescribed_medication: {title: '医嘱用药', list: prescribed},
          progress_note: {title: '病程记录+化验结果', list: course},
        },
        activeName: '1',
        progressForm: {},
        icuWeChat: {},
        submitData: {},
        isSelect: false,
        sonRefresh: true,
        ruleFormData: {},
        auditStatus:'',

      }
    },
    created() {
      let icuWeChat = {};
      icuWeChat.created_by = getQueryVariable('created_by');
      icuWeChat.template_id = parseInt(getQueryVariable('template_id'));
      icuWeChat.patient_id = parseInt(getQueryVariable('patient_id'));
      icuWeChat.template_link = decodeURIComponent(getQueryVariable('template_link'));
      this.isSelect = getQueryVariable('isSelect');

      let id = getQueryVariable('id');

      console.log(id)
      this.icuWeChat = icuWeChat;
      sessionStorage.setItem('icuWeChat', JSON.stringify(icuWeChat))

      Object.values(this.formData).forEach(item => {
        item.list.forEach(child => {
          item[child.name] = this.returnType(child.type);
          item.image_list = [];
        })
      })

      if (id) {
        this.id = id
        this.getCaseRecords();
      }

      // this.wxStartCode()
    },
    methods: {

      //获取病历详情信息
      getCaseRecords() {
        let query = {
          created_by: this.icuWeChat.created_by
        }
        console.log(query)
        this.$ajaxApi.dataApi.getCaseRecords(this.id, query)
          .then(res => {
            console.log(res)
            console.log(res.data.status_code)
            if (res.data.status_code === 200) {
              this._.merge(this.formData,res.data.data.template_content);
              console.log(this.formData)
              this.sonRefresh= false;
              this.$nextTick(() => {
                this.sonRefresh= true;
              });
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      //选择模板
      selectClick() {

        let data = {
          created_by: this.icuWeChat.created_by,
          template_id: this.icuWeChat.template_id,
        }
        this.$ajaxApi.dataApi.myTemplates(data)
          .then(res => {
            console.log(res)
            console.log(res.data.status_code)
            if (res.data.status_code === 200) {
              // this.$message.success('提交成功');
              // wx.miniProgram.redirectTo({
              //   url: '../patientDetail/patientDetail?patient_id=' + this.icuWeChat.patient_id + '&callBack=true'
              // })
              this.$message.success('选择成功');
              setTimeout(() => {
                wx.miniProgram.navigateBack({
                  delta: 1,
                })
              }, 800)
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            console.log(error)
          })
      },

      //form提交返回值
      returnData(data) {
        Object.values(data).map(item => {
          delete item.list
        })
        this.submitData = data;
        console.log(this.submitData)
        if(this.id){
          this.uploadCaseRecords();
        }else{
          this.caseRecords();
        }
      },
      saveClick() {
        this.auditStatus = '';
        this.$refs.child.submitForm();
      },
      tsSaveClick() {
        this.auditStatus = 'STAGED'
        this.$refs.child.submitForm();
      },
      //正常保存，获取保存数据，保存成功后返回上一页
      caseRecords() {
        let data = {
          ...this.icuWeChat,
          template_content: {...this.submitData},
        }
        if(this.auditStatus!==''){
          data.audit_status = this.auditStatus
        }
        this.$ajaxApi.dataApi.caseRecords(data)
          .then(res => {
            console.log(res)
            console.log(res.data.status_code)
            if (res.data.status_code === 200) {
              this.$message.success('提交成功');
              setTimeout(() => {
                wx.miniProgram.navigateBack({
                  delta: 1,
                })
              }, 800)
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            console.log(error)
          })
      },
      //有id的情况下进行更新
      uploadCaseRecords(){
        let data = {
          ...this.icuWeChat,
          template_content: {...this.submitData},
        }
        if(this.auditStatus!==''){
          data.audit_status = this.auditStatus
        }
        this.$ajaxApi.dataApi.uploadCaseRecords(this.id,data)
            .then(res => {
              console.log(res)
              console.log(res.data.status_code)
              if (res.data.status_code === 200) {
                this.$message.success('提交成功');
                setTimeout(() => {
                  wx.miniProgram.navigateBack({
                    delta: 1,
                  })
                }, 800)
              } else {
                this.$message.error(res.data.message);
              }
            })
            .catch(error => {
              console.log(error)
            })
      },


      returnType(value) {
        let type;
        switch (value) {
          case 'input':
            type = '';
            break;
          case 'select':
            type = '';
            break;
          case 'check':
            type = [];
            break;
          case 'radio':
            type = '';
            break;
          case 'textarea':
            type = '';
            break;
          case 'date':
            type = '';
            break;
          case 'hidden':
            type = '';
            break;
        }
        return type;
      },

    }

  }
</script>

<style scoped>
  .home {
    padding-bottom: 100px;
  }

  .content {
    padding: 0 18px;
  }

  .home .collapse-wrap /deep/ .el-collapse-item__header {
    /*padding: 0 25px;*/
    color: #030303;
    font-size: 16px;
    border-bottom-color: #F7F9FA;
  }

  .save-wrap {
    position: fixed;
    padding: 4px 0 11px;
    width: 100%;
    background-color: #FFF;
    bottom: 0;
    border-top: 1px solid #CFCFCF;
    z-index: 999;

  }

  .save-tips {
    font-size: 14px;
    color: #030303;
    padding: 5px 0;
  }

  .save-btn {
    padding: 9px 64px;
    color: #44A4E6;
    font-size: 16px;
    border: 1px solid #44A4E6;
  }
  .ts-btn {
    color: #FFFFFF;
    background: #44A4E6;
    border: 1px solid #44A4E6;
  }
</style>

