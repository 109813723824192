import axios from 'axios'
import {getQueryVariable} from "@/libs/util";

let baseUrl = ''
let baseUrlTwo = ''
let adminUrl = ''

// let baseUrlThree = '';
// let fullUrl = ''
let token = getQueryVariable('token');
let headers = {
  'x-client-tag': 'MP',
  'x-token': token,
}
baseUrl = '/api2/v1/app';
baseUrlTwo = '/api/v1/app';
adminUrl = '/api2/v1/admin';
// baseUrlTwo = '/api/v2/app';
// baseUrlThree = '/api/v3/app';

// fullUrl = 'https://yunicu-api.we-fire.cn/api/v1/app';
// baseUrlTwo = 'https://case-h5.yunicu.com/api/v1/app';
// baseUrl = 'https://yunicu-api.yunicu.com/api2/v1/app';
// adminUrl = 'https://case-h5.yunicu.com/api2/v1/admin'

//
// baseUrl = 'https://yunicu-api.we-fire.cn/api2/v1/app';
// baseUrlTwo = 'https://yunicu-api.we-fire.cn/api/v1/app';
// adminUrl = 'https://yunicu-api.we-fire.cn/api2/v1/admin'


const dataApi = {
  /**
   *识别
   */
  textRecognition(data) {
    return axios({
      headers: headers,
      url: baseUrl + '/textRcognition/info',
      data: data,
      method: 'POST',
    })
  },
  /**
   *保存
   */
  caseRecords(data) {
    return axios({
      headers: headers,
      url: baseUrl + '/caseRecords',
      data: data,
      method: 'POST',
    })
  },
  /**
   *获取我的病历模板
   */
  myTemplates(data) {
    return axios({
      headers: headers,
      url: baseUrl + '/myTemplates',
      data: data,
      method: 'POST',
    })
  },
  /**
   *获取病历模板详情
   */
  getCaseRecords(id, query) {
    return axios({
      headers: headers,
      url: baseUrl + '/caseRecords/' + id,
      params: query,
      method: 'GET',
    })
  },
  /**
   *上传文件
   */
  uploadFile(query, data) {
    return axios({
      headers: headers,
      url: baseUrl + '/common/uploadFile?created_by=' + query.created_by + '&patient_id=' + query.patient_id + '&template_id=' + query.template_id,
      data: data,
      method: 'POST',
    })
  },
  uploadFileUrl : baseUrl + '/common/uploadFile',
  /**
   *修改病历详情
   */
  uploadCaseRecords(id,data) {
    return axios({
      headers: headers,
      url: baseUrl + '/caseRecords/' + id,
      data: data,
      method: 'PUT',
    })
  },

  /**
   *检测该病例是否已上传
   */
  caseRecordsCheck(data) {
    return axios({
      headers: headers,
      url: baseUrl + '/caseRecords/check',
      data: data,
      method: 'POST',
    })
  },
  /**
   *保存
   */
  caseRecordsUN(data) {
    return axios({
      headers: headers,
      url: baseUrl + '/caseRecords/uncheck',
      data: data,
      method: 'POST',
    })
  },
  /**
   *修改病历详情
   */
  uploadCaseRecordsUN(id,data) {
    return axios({
      headers: headers,
      url: baseUrl + '/caseRecords/uncheck/' + id,
      data: data,
      method: 'PUT',
    })
  },
}
const wechatApi = {
  /**
   *获取学分信息
   */
  getWechat(data) {
    return axios({
      headers: headers,
      url: baseUrlTwo + '/wechat/fetch-sdk-params',
      method: 'POST',
      data: data
    })
  },
}

const adminApi = {

  /**
   *获取学分信息
   */
  getCaseRecords(id) {
    return axios({
      headers: headers,
      url: adminUrl + '/caseRecords/' + id,
      method: 'GET',
    })
  },
  /**
   *获取学分信息
   */
  putCaseRecords(id,data) {
    return axios({
      headers: headers,
      url: adminUrl + '/caseRecords/' + id + '/coding_image_list',
      method: 'PUT',
      data,
    })
  },
  /**
   *获取学分信息
   */
  uploadFile(data) {
    return axios({
      url: adminUrl + '/common/uploadFile',
      method: 'POST',
      data,
    })
  },
}

export default {
  dataApi,
  wechatApi,
  adminApi
}
